import React, { useState } from 'react';
import DataGrid, { Button, Column, Paging } from 'devextreme-react/data-grid';
import PopupNewMember from '../popups/PopupNewMember';
import { object, shape } from 'prop-types';
import { IconButton } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PopupMemberDelete from '../popups/PopupMemberDelete';

import {
  Container,
  DatagridContainer,
  Section,
  SectionHeader,
  Title,
} from './styles';

function EmployeesGrid({ data, ACL }) {
  const [popupNewMemberVisible, setPopupNewMemberVisible] = useState(false);
  const [popupDeleteVisible, setPopupDeleteVisible] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const employeeData = data?.data?.Members;

  function handleOpenPopupDelete(data) {
    setCurrentMember(data);
    setPopupDeleteVisible(true);
  }

  function handleClosePopupDelete() {
    setPopupDeleteVisible(false);
    setCurrentMember(null);
  }
  return (
    <>
      {popupNewMemberVisible && (
        <PopupNewMember
          visible={popupNewMemberVisible}
          onClose={() => setPopupNewMemberVisible(false)}
          type="MEMBER"
          existentMembers={employeeData}
          currentGroup={{
            GroupCN: data?.data?.GroupCN,
            DName: data?.data?.DName,
          }}
          currentMember={{}}
        />
      )}
      {popupDeleteVisible && (
        <PopupMemberDelete
          memberData={currentMember}
          visible={popupDeleteVisible}
          onClose={() => handleClosePopupDelete()}
          groupName={data?.data?.GroupCN}
          groupDName={data?.data?.DName}
        />
      )}
      <Container>
        <Section>
          <SectionHeader>
            <Title>Members</Title>
            {(ACL?.Update || ACL?.Admin) && (
              <IconButton
                aria-label="plus"
                size="small"
                onClick={() => setPopupNewMemberVisible(true)}
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            )}
          </SectionHeader>
          <DatagridContainer>
            <DataGrid
              height={300}
              dataSource={employeeData}
              keyExpr="DisplayName"
              showBorders
              selection={{
                mode: 'single',
              }}
              hoverStateEnabled
            >
              <Paging enabled={false} />
              <Column dataField="DisplayName" caption="Name" />
              <Column dataField="JobTitle" caption="Job Title" />
              <Column dataField="Department" />
              <Column dataField="Email" caption="Email" />
              {(ACL?.Delete || ACL?.Admin) && (
                <Column type="buttons" caption="Actions">
                  {(ACL?.Delete || ACL?.Admin) && (
                    <Button
                      icon="trash"
                      text="Delete"
                      onClick={(e) => handleOpenPopupDelete(e?.row?.data)}
                    />
                  )}
                </Column>
              )}
            </DataGrid>
          </DatagridContainer>
        </Section>
      </Container>
    </>
  );
}

EmployeesGrid.propTypes = {
  data: shape({
    data: shape({}),
  }).isRequired,
  ACL: object.isRequired,
};

export default EmployeesGrid;
