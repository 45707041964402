/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import DataGrid, {
  Column,
  MasterDetail,
  Selection,
} from 'devextreme-react/data-grid';
// import { Button as HeaderButton } from 'devextreme-react/button';
import useStyles from '../styles/styles';
import { Template } from 'devextreme-react/core/template';

import usePermissions from '../../hooks/usePermissions';
import Switch from '../../components/Switch';
import { BreadCrumbRender, LoadPermissions } from '../../global';

import { Header, WrapperLeft, Title } from './styles';
import EmployeesGrid from './components/EmployeesGrid';
import useGetDistLists from '../../services/requests/useGetDistLists';
import useGetDistListsByUser from '../../services/requests/useGetDistListsByUser';
import GroupListGrid from './components/GroupListGrid';
import { LoadPanel } from 'devextreme-react';

function DistributionList() {
  const [isEmployeesView, setIsEmployeesView] = useState(true);
  const classes = useStyles();
  const { data: distList, isLoading: loadingDistList } = useGetDistLists();

  const {
    data: distListByUser,
    isLoading: loadingDistListByUser,
  } = useGetDistListsByUser();

  /** *******************************
   * Access Control
   ******************************** */
  const ACL = usePermissions();

  while (ACL === undefined) {
    return <LoadPermissions>Loading permissions</LoadPermissions>;
  }
  if (ACL.NoAccess) {
    return (
      <LoadPermissions>
        You don&lsquo;t have access to this page
      </LoadPermissions>
    );
  }
  /** ****************************** */

  const newDistList = distList?.map((distItem) => ({
    ...distItem,
    Members:
      distItem.Members?.map((member) => {
        const matchingUser = distListByUser?.find(
          (user) => user?.DistinguishedName === member?.DistinguishedName
        );
        return matchingUser
          ? { ...member, Username: matchingUser?.Username }
          : member;
      }) || [],
  }));

  return (
    <div className={classes.divGridContainer}>
      <LoadPanel
        visible={loadingDistListByUser || loadingDistList}
        message="Loading..."
        shadingColor="rgba(255,255,255,0.4)"
        closeOnOutsideClick={false}
      />
      <Header>
        <WrapperLeft>
          <Title>Contacts/Distribution List</Title>
          <Switch
            id="switch-view"
            toggled={!isEmployeesView}
            onChange={() => setIsEmployeesView(!isEmployeesView)}
            iconOn="fa fa-envelope"
            iconOff="fas fa-users"
            title={
              isEmployeesView
                ? 'Switch to Distribution List View'
                : 'Switch to Employees View'
            }
            description={
              isEmployeesView ? 'By Employees' : 'By Distribution List View'
            }
          />
        </WrapperLeft>
        {/* {(ACL?.AddNew || ACL?.Admin) && (
          <HeaderButton
            icon="plus"
            onClick={() => handleOpenPopupGroup(null, 'NEW')}
          />
        )} */}
      </Header>
      <DataGrid
        keyExpr="DisplayName"
        dataSource={distListByUser}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        visible={isEmployeesView}
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
      >
        <MasterDetail
          enabled
          component={({ data }) => (
            <GroupListGrid data={data} ACL={ACL} distList={distList} />
          )}
        />

        <Selection mode="single" />
        <Column dataField="DisplayName" caption="Name" />
        <Column dataField="JobTitle" />
        <Column dataField="Department" />
        <Column dataField="Email" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
      <DataGrid
        keyExpr="Nickname"
        dataSource={newDistList}
        allowColumnReordering
        showBorders
        rowAlternationEnabled
        focusedRowEnabled
        showRowLines
        headerFilter={{ visible: true }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        visible={!isEmployeesView}
      >
        <MasterDetail
          enabled
          component={({ data }) => <EmployeesGrid data={data} ACL={ACL} />}
        />
        <Selection mode="single" />
        <Column dataField="GroupCN" caption="Group" />
        <Column dataField="Mail" caption="Email" />
        <Column dataField="ManagerDisplay" caption="Owner" />
        <Column dataField="Nickname" />
        <Template name="breadCrumb" render={() => BreadCrumbRender(ACL)} />
      </DataGrid>
    </div>
  );
}

export default DistributionList;
